import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const ImmobilienKaufenUndVermieten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Immobilien kaufen und vermieten – zahlt sich das aus?" showCalc={false} />
            <Article>
                <p>
                    Seit dem Aufkommen des modernen Kapitalismus gelten Immobilien als eine der sichersten
                    Anlagemöglichkeiten für Investor:innen. Kein Wunder – lässt sich das Wohnen ungeachtet aller
                    Veränderung der Einrichtungsmoden doch zu den menschlichen Grundbedürfnissen zählen. Darüber hinaus
                    haben sich Häuser und Wohnungen nicht nur als besonders wertbeständig erwiesen, gerade in der
                    jüngeren Vergangenheit steigen die Immobilienpreise wieder drastisch. Aber lohnt sich der Erwerb
                    einer Immobilie zum Zweck ihrer Vermietung tatsächlich für jede:n? Im folgenden Artikel gehen wir
                    dieser Frage auf den Grund.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Die Immobilienpreisentwicklung in Österreich</h2>
                <p>
                    Wenn es um die Frage nach dem Kauf einer Immobilie zu Vermietungszwecken geht, so ist es
                    empfehlenswert, zunächst die jüngsten Entwicklungen auf dem Markt in den Blick zu nehmen. Auf diesem
                    ist aktuell eine rasche Preisentwicklung nicht nur von Objekten in urbanen Ballungsräumen, sondern
                    auch von solchen in weniger gefragten Umgebungen zu beobachten. 2022 sind die mittleren Preise für
                    Wohnimmobilien im ersten Quartal um 12,3 % gestiegen. Am stärksten fiel der Anstieg in diesem
                    Zeitraum bei Einfamilienhäusern außerhalb Wiens aus: Er betrug 16,1 %. Hinter dieser
                    Preisentwicklung fällt sogar jene von neuen Eigentumswohnungen in der Bundeshauptstadt zurück, die
                    sich mit 12,8 % allerdings ebenfalls im zweistelligen Bereich bewegte. Zurückführen lässt sich diese
                    Preisentwicklung auf die höchste Inflationsrate seit den 80er Jahren sowie auf den negativen Wert
                    der Real
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        zinsen
                    </Link>{" "}
                    von Anleihen. Beim Erwerb einer Immobilie für die Vermietung muss man also die Tatsache bedenken,
                    dass derzeit auch auf dem österreichischen Markt erste Anzeichen einer Immobilienblase zu finden
                    sind. Platz diese, so verliert auch deine als Anlage erstandene Wohnung an Wert.
                </p>
                <hr />

                <h2>Die Kunst des Vermietens – oder: Rechnen</h2>
                <p>
                    Sofern das Mietverhältnis keine Probleme bereitet und sich das Objekt gut finanzieren lässt, stellt
                    die Vermietung von Immobilien eine attraktive Option für eine zusätzliche, regelmäßige
                    Einkommensquelle dar. Insbesondere bereits vorhandene Immobilien, die andernfalls leer stünden,
                    eignen sich somit bestens für die Vermietung an Dritte. Wenn du den betreffenden Wohnraum allerdings
                    zuerst erwerben musst, solltest du eine detaillierte Aufstellung über sämtliche Kosten anfertigen,
                    die dieser Kauf nach sich zieht, und ermitteln, ab welchem Zeitpunkt die voraussichtlichen
                    Mieteinnahmen diese übersteigen – ab wann du also Gewinn erzielen würdest. Denn bis dahin musst du
                    die{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Tilgung
                    </Link>{" "}
                    des zur Anschaffung der vermieteten Wohnfläche aufgenommenen Kredites aus deinem derzeitigen
                    Einkommen finanzieren. Die idealen Konditionen findest du übrigens mit unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    . Vergiss nicht, in dieser Rechnung sämtliche{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Nebenkosten
                    </Link>{" "}
                    zu berücksichtigen, die beim Immobilienkauf anfallen. Erst dann kannst du realistisch abschätzen, ob
                    du die Finanzierungskosten vor dem Zeitpunkt, ab dem du die Immobilie gewinnbringend vermietest,
                    problemlos tragen kannst. Schließlich musst du auch die Steuerpflichtigkeit deiner Mieteinnahmen
                    bedenken. Holst du dir bei der betreffenden Einkommenssteuererklärung Hilfe bei einer
                    professionellen Steuerberatung, so fallen wiederum zusätzliche Kosten an.
                </p>
                <hr />

                <h2>Deine Pflichten als Vermieter:in</h2>
                <p>
                    Als zukünftige Vermieter:in solltest du ebenfalls bedenken, dass der Rechtsschutz für Mieter:innen
                    in Österreich stark ausgeprägt ist. Um die Kaution einbehalten oder Mietverträge auflösen zu können,
                    musst du gute Gründe angeben und die entsprechenden Tatbestände auch beweisen können – nötigenfalls
                    vor Gericht. Denn was Kaution, Befristung und Kündigung betrifft, kennt das Mietrechtsgesetz
                    eindeutige Regelungen, die sich kaum anfechten lassen. Sind unverzichtbare Maßnahmen zur
                    Instandhaltung des betreffenden Objekts nötig, so haben deine Mieter:innen umgekehrt allerdings die
                    Möglichkeit, von dir deine sogenannte Erhaltungspflicht einzufordern. Auch ist es ihnen möglich, die
                    Betriebskostenabrechnung einzusehen. Weil du dich als Haus- oder Wohnungseigentümer:in in der
                    finanziell stärkeren Lage befindest, versetzt dich das Mietrecht in die rechtlich schwächere.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"immobilienKaufenUndVermieten"}></BreadcrumbList>
            <ArticleStructuredData
                page={"immobilienKaufenUndVermieten"}
                heading={"Immobilien kaufen und vermieten – zahlt sich das aus?"}
            />
        </Layout>
    );
};

export default ImmobilienKaufenUndVermieten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.immobilien-kaufen-und-vermieten", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
